import React from 'react';
import { Popup } from 'react-map-gl';

const CinemaPopup = ({ cinema, onClose }) => {
    if (!cinema) return null;

    return (
        <Popup
            longitude={cinema.coordinates.lon}
            latitude={cinema.coordinates.lat}
            anchor="top"
            onClose={onClose}
            closeButton={true}
            closeOnClick={false}
        >
            <div className="p-2">
                <h3 className="font-bold mb-1">{cinema.name}</h3>
                <p className="text-sm text-gray-600 mb-1">{cinema.address}</p>
                {cinema.url && (
                    <a
                        href={cinema.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline text-sm"
                    >
                        Voir sur Allociné
                    </a>
                )}
            </div>
        </Popup>
    );
};

export default CinemaPopup;