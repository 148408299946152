// App.jsx
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import CinemaMap from './components/Map';
import { Loading, Error } from './components/Loading';
import { useCinemas } from './hooks/useCinemas';
import { useMovies } from './hooks/useMovies';

const App = () => {
    const { cinemas, loading, error } = useCinemas();
    const { movies, loadingMovie, errorMovie } = useMovies();
    const [isMobile, setIsMobile] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    useEffect(() => {
        const checkMobile = () => setIsMobile(window.innerWidth < 768);
        checkMobile();
        window.addEventListener('resize', checkMobile);
        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    if (loading || loadingMovie) return <Loading message="Chargement de la carte..." />;
    if (error || errorMovie) return <Error message={error || errorMovie} />;

    const validCinemas = cinemas.filter(cinema =>
        cinema.coordinates.lat != null && cinema.coordinates.lon != null
    );

    return (
        <div style={{
            position: 'relative',
            height: '100vh',
            width: '100%'
        }}>
            <div style={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0
            }}>
                <CinemaMap cinemas={validCinemas} />
            </div>

            {isMobile && (
                <motion.div
                    style={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        bottom: 0,
                        height: '95vh',
                        backgroundColor: 'white',
                        borderTopLeftRadius: '24px',
                        borderTopRightRadius: '24px',
                        boxShadow: '0 -4px 6px -1px rgba(0, 0, 0, 0.1)',
                        zIndex: 9999,
                        pointerEvents: 'auto'
                    }}
                    drag="y"
                    dragConstraints={{ top: 0, bottom: 0 }}
                    dragElastic={0.2}
                    initial={{ y: "65%" }}
                    animate={{ y: isDrawerOpen ? "0%" : "65%" }}
                    transition={{ type: "spring", stiffness: 300, damping: 30 }}
                    onDragEnd={(_, info) => {
                        if (info.offset.y < -50) {
                            setIsDrawerOpen(true);
                        } else if (info.offset.y > 50) {
                            setIsDrawerOpen(false);
                        }
                    }}
                >
                    <div style={{
                        height: '60px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'grab'
                    }}>
                        <div style={{
                            width: '48px',
                            height: '6px',
                            backgroundColor: '#E5E7EB',
                            borderRadius: '9999px'
                        }} />
                    </div>

                    <div style={{ padding: '0 16px' }}>
                        <h2 style={{
                            fontSize: '1.25rem',
                            fontWeight: 'bold',
                            marginBottom: '1rem'
                        }}>
                            Prochaines séances
                        </h2>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                            {movies.slice(0, 5).map(movie => (
                                <div key={movie.id} style={{ padding: '0.5rem' }}>
                                    <h3 style={{ fontWeight: '600' }}>{movie.title}</h3>
                                </div>
                            ))}
                        </div>
                    </div>
                </motion.div>
            )}
        </div>
    );
};

export default App;