import { useState, useEffect } from 'react';
import { formatCinemaData } from '../utils/mapUtils';

export const useCinemas = () => {
    const [cinemas, setCinemas] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCinemas = async () => {
            try {
                setLoading(true);
                // Charger directement le fichier JSON statique
                const response = await fetch('/data/cinemas.json');

                const data = await response.json();
                // Formater les données des cinémas
                const formattedCinemas = Object.values(data.cinemas)
                    .map(formatCinemaData)
                    .filter(cinema =>
                        cinema.coordinates &&
                        cinema.coordinates.lat != null &&
                        cinema.coordinates.lon != null
                    );
                setCinemas(formattedCinemas);
                setError(null);
            } catch (error) {
                console.error('Error fetching cinema data:', error);
                setError('Erreur lors du chargement des cinémas');
            } finally {
                setLoading(false);
            }
        };

        fetchCinemas();
    }, []);

    return { cinemas, loading, error };
};