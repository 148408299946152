export const formatCinemaData = (cinema) => ({
    id: cinema.id,
    name: cinema.name,
    address: cinema.address,
    url: cinema.url,
    coordinates: cinema.coordinates || {
        lat: null,
        lon: null
    }
});
export const formatMovieData = (movie) => ({
    id: movie.id,
    title: movie.title,
    duration: movie.duration,
    poster: movie.poster,
    cinemas: movie.cinemas?.map(cinema => ({
        id: cinema.id,
        name: cinema.name
    })) || []
});
