import React, { useRef, useState, useEffect } from 'react';
import Map, { Marker } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import CustomMarker from './CustomMarker';
import CinemaPopup from './CinemaPopup';

const CinemaMap = ({ cinemas }) => {
    const [selectedCinema, setSelectedCinema] = useState(null);
    const mapRef = useRef();

    useEffect(() => {
        console.log('Selected cinema:', selectedCinema);
    }, [selectedCinema]);

    const handleMarkerClick = (cinema) => {
        console.log("Clicking on cinema:", cinema);
        // Assurons-nous que les coordonnées sont présentes
        if (cinema.coordinates && cinema.coordinates.lat && cinema.coordinates.lon) {
            setSelectedCinema(prevSelected =>
                prevSelected?.id === cinema.id ? null : cinema
            );
        }
    };

    const handlePopupClose = () => {
        console.log("Closing popup");
        setSelectedCinema(null);
    };

    return (
        <Map
            ref={mapRef}
            initialViewState={{
                longitude: 2.3488,
                latitude: 48.8534,
                zoom: 12,
                pitch: 0,
                bearing: 0
            }}
            style={{ width: '100%', height: '100vh' }}
            mapStyle="mapbox://styles/mapbox/streets-v11"
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
            terrain={null}
            maxPitch={0}
            antialias={true}
            preserveDrawingBuffer={true}
            renderWorldCopies={false}
            maxBounds={[
                [2.0, 48.5],
                [2.6, 49.0]
            ]}
        >
            {cinemas.map((cinema) => (
                <Marker
                    key={cinema.id}
                    longitude={cinema.coordinates.lon}
                    latitude={cinema.coordinates.lat}
                    anchor="bottom"
                >
                    <CustomMarker
                        cinema={cinema}
                        onClick={handleMarkerClick}
                    />
                </Marker>
            ))}

            <CinemaPopup
                cinema={selectedCinema}
                onClose={handlePopupClose}
            />
        </Map>
    );
};

export default CinemaMap;