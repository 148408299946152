import { useState, useEffect } from 'react';
import { formatMovieData } from '../utils/mapUtils';

export const useMovies = () => {
    const [movies, setMovies] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchMovies = async () => {
            try {
                setLoading(true);
                // Charger directement le fichier JSON statique
                const response = await fetch('/data/movies.json');

                const data = await response.json();
                // Formater les données des cinémas
                const formattedMovies = Object.values(data.movies)
                    .map(formatMovieData);
                setMovies(formattedMovies);
                setError(null);
            } catch (error) {
                console.error('Error fetching movie data:', error);
                setError('Erreur lors du chargement des films');
            } finally {
                setLoading(false);
            }
        };

        fetchMovies();
    }, []);

    return { movies, loading, error };
};